<template>
    <div class="home-page wid1200">

        <div class="left-part">

            <div class="banner-wrap type-cont-lt">

                <ul class="type-iterm fl">
                    <li>
                        <div>
                            <img src="../assets/img/typeicon.png" alt="" />
                            <p>集刊分类</p>
                            <img class="more" src="../assets/img/more.png" />
                        </div>

                    </li>
                    <li :class="{'active':curIndex==index}" v-for="(iterm,index) in typeList" :key="iterm.name"
                        @mouseover="changeTab(index)">
                        <a class="type-title">{{iterm.name}}</a>
                        <div class="type-pop">
                            <div class="pop-box">
                                <div class="type-name" v-for="indexName in iterm.child" :key="indexName.id">
                                    <span>{{indexName.name}}</span>
                                    <a v-for="type in indexName.child" :key="type.id"
                                        @click="toJKHome(type.code, type.name)">{{type.name}}</a>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
                <banner-swiper :dataList="newsData" :pageName="pageName" />
                <!-- <div class="rtImg fl">
                        <div class="swiper-container lunp " >
                            <div class="swiper-wrapper">
                                <div class="swiper-slide" v-for="item in swiperList" :key="item.id">
                                        <a :href="item.url" target="_blank">
                                            <img :src="item.photo" v-if="item.photo" alt="">
                                        </a>
                                </div>
                            </div>
                            <div class="pagination"></div>
                        </div>
                    </div> -->

            </div>
            <div class="library-list" v-for="(item, index) in libraryData" :key="index">
                <div class="com-title">
                    <div>
                        <img src="../assets/img/library.png" alt="" />
                        <h5 style="cursor: pointer;">
                            <router-link :to="{path: '/library/l', query:{id:item.id}}">{{item.name}}</router-link>
                        </h5>
                    </div>

                    <div>
                        <!-- <ul>
                            <li v-for="i in item.customChildLibraryData" :key="i.id" v-if="i.name!='推荐信息'">
                                <router-link :to="{path: '/library/l', query:{id:item.id}}">{{i.name}}</router-link>

                            </li>
                        </ul> -->
                        <span>
                            <router-link :to="{path: '/library/l', query:{id:item.id}}">更多<img
                                    src="../assets/img/more.png" alt="" /></router-link>
                        </span>
                    </div>
                </div>

                <div class="library-content">
                    <ul class="library-title-ul">
                        <li :class="[activeData['activeIndex'+index]==0 ? 'active' : '']"
                            @click="switchMode(index, 0, item.id, item)">学术论文</li>

                        <li :class="[activeData['activeIndex'+index]==1 ? 'active' : '']"
                            @click="switchMode(index, 1, item.id, item)">学者机构</li>
                        <li :class="[activeData['activeIndex'+index]==2 ? 'active' : '']"
                            @click="switchMode(index, 2, item.id, item)">集刊文集</li>
                        <li :class="[activeData['activeIndex'+index]==3 ? 'active' : '']"
                            @click="switchMode(index, 3, item.id, item)">图表图片</li>
                        <li :class="[activeData['activeIndex'+index]==4 ? 'active' : '']"
                            @click="switchMode(index, 4, item.id, item)">专题</li>
                    </ul>

                    <div class="content-wrap">
                        <!-- 学术论文-->
                        <ul class="academic" v-if="activeData['activeIndex'+index] == 0">
                            <li class="fl" style="white-space: wrap !important;">
                                <!-- item.customChildLibraryData -->
                                <img class="fl" v-if="item.logo1" :src="item.logo1 + '?w=116&h=70'"
                                    @click="goArticle(item.customArticleData[0].id, item.customArticleData[0].title)"
                                    alt="" />
                                <img class="fl" v-else alt="" />
                                <div class="fl" style="width: 260px;">
                                    <h5 v-if="item.customArticleData && item.customArticleData[0] "
                                        @click="goArticle(item.customArticleData[0].id, item.customArticleData[0].title)">
                                        {{item.customArticleData[0].searchTitle ? item.customArticleData[0].searchTitle : item.customArticleData[0].title}}</h5>
                                    <p v-if="item.customArticleData && item.customArticleData[0] && item.customArticleData[0].abstractCn"
                                        style="overflow: hidden; ">
                                        {{item.customArticleData[0].abstractCn}}
                                    </p>
                                </div>
                            </li>
                            <li class="fl" v-for="(i, ind) in item.customArticleData" :key="i.id" v-if="11>ind && ind>0"
                                @click="goArticle(i.id, i.title)">{{i.searchTitle? i.searchTitle : i.title}}</li>
                            <div class="clear"></div>
                        </ul>
                        <!-- 学者机构、集刊文集 -->
                        <div class="learner-ins"
                            v-if="activeData['activeIndex'+index] == 1 || activeData['activeIndex'+index] == 2">

                            <!-- 学者机构 -->
                            <ul class="learner-ins-ul" v-show="(activeData['activeIndex'+index]) == 1">
                                <li>
                                    <div>
                                        <!-- <img src="../assets/img/book.png" alt="" /> -->
                                        <img v-if="item.customExpertData[0] && item.customExpertData[0].picture"
                                            :src="item.customExpertData[0].picture + '?w=116&h=150'" alt=""
                                            @click="goExp(item.customExpertData[0].relateId)">
                                        <img v-else alt="" />
                                    </div>
                                    <div class="right-learner" v-if="item.customExpertData[0]">
                                        <h5 @click="goExp(item.customExpertData[0].relateId)">
                                            {{item.customExpertData[0].relateName}}
                                        </h5>
                                        <p>
                                            {{item.customExpertData[0].description}}
                                        </p>
                                    </div>

                                </li>
                                <li>
                                    <div>
                                        <img v-if="item.customInsData[0] && item.customInsData[0].picture"
                                            :src="item.customInsData[0].picture + '?w=116&h=150'" alt=""
                                            @click="goIns(item.customInsData[0].relateId)">
                                        <img v-else alt="" />
                                    </div>
                                    <div class="right-learner" v-if="item.customInsData[0]">
                                        <h5 @click="goIns(item.customInsData[0].relateId)">
                                            {{item.customInsData[0].relateName}}</h5>
                                        <p>
                                            {{item.customInsData[0].description}}
                                        </p>
                                    </div>
                                </li>
                            </ul>
                            <ul class="bot-learner-ins-ul" v-show="(activeData['activeIndex'+index]) == 1">
                                <li class="left-expert expert-sty">
                                    <p v-for="(i, ind) in item.customExpertData" :key="i.id"
                                        v-if="7>ind && ind>0 && item.customExpertData[1]" @click="goExp(i.relateId)">
                                        {{i.relateName}}</p>
                                </li>
                                <li class="right-ins ins-sty">
                                    <p v-for="(i, ind) in item.customInsData" :key="ind"
                                        v-if="5>ind && ind>0 && item.customInsData[1]" @click="goIns(i.relateId)">
                                        {{i.relateName}}</p>
                                </li>
                            </ul>

                            <!-- 集刊文集 -->
                            <ul class="learner-ins-ul" v-if="(activeData['activeIndex'+index]) == 2">
                                <li>
                                    <div>
                                        <!-- <img src="../assets/img/book.png" alt="" /> -->
                                        <img v-if="item.customBookData[0] && item.customBookData[0].img"
                                            :src="item.customBookData[0].img + '?w=116&h=150'" alt=""
                                            @click="goCollected(item.customBookData[0])">
                                        <img v-else alt="" />
                                    </div>
                                    <div class="right-learner" v-if="item.customBookData[0]">
                                        <h5 @click="goCollected(item.customBookData[0])">
                                            <!-- {{activeData['activeIndex'+index]}}    -->
                                            {{item.customBookData[0].title}}
                                        </h5>
                                        <p v-html="item.customBookData[0].abstractCn">
                                        </p>
                                    </div>

                                </li>
                                <li>
                                    <div>
                                        <!-- <img src="../assets/img/book.png" alt="" /> -->
                                        <img v-if="item.customWenjiData[0] && item.customWenjiData[0].img"
                                            :src="item.customWenjiData[0].img + '?w=116&h=150'" alt=""
                                            @click="goWenji(item.customWenjiData[0])">
                                        <img v-else alt="">
                                    </div>
                                    <div class="right-learner" v-if="item.customWenjiData[0]">
                                        <h5 @click="goWenji(item.customWenjiData[0])">{{item.customWenjiData[0].title}}
                                        </h5>
                                        <p v-html="item.customWenjiData[0].abstractCn">
                                            
                                        </p>
                                    </div>
                                </li>
                            </ul>

                            <ul class="bot-learner-ins-ul" v-if="(activeData['activeIndex'+index]) == 2">
                                <li class="left-expert collected-sty">
                                    <p v-for="(i, ind) in item.customBookData" :key="i.id"
                                        v-if="7>ind && ind>0 && item.customBookData[1]" @click="goCollected(i)">
                                        {{i.title}}</p>
                                </li>
                                <li class="right-ins wenji-sty">
                                    <p v-for="(i, ind) in item.customWenjiData" :key="ind"
                                        v-if="5>ind && ind>0 && item.customWenjiData[1]" @click="goWenji(i)">{{i.title}}
                                    </p>
                                </li>
                            </ul>

                        </div>

                        <!-- 图表图片 -->
                        <div class="chart-img" v-if="activeData['activeIndex'+index]==3">
                            <div>

                                <img v-if="item.customChartData[0]" :src="item.customChartData[0].img + '?w=360&h=150'" alt=""
                                    @click="goChartPic(item.customChartData[0].id)" />
                                <img v-else alt="" />
                                <p v-if="item.customChartData && item.customChartData[0] && item.customChartData[0].title"
                                    @click="goChartPic(item.customChartData[0].id)">{{item.customChartData[0].title}}
                                </p>

                                <h6 v-if="item.customChartData && item.customChartData[1]"
                                    @click="goChartPic(item.customChartData[1].id)">
                                    {{item.customChartData[1].title}}</h6>
                                <!-- <h6 v-if="item.customChartData[2]" @click="goChartPic(item.customChartData[2].id)">
                                    {{item.customChartData[2].title}}</h6> -->
                            </div>
                            <div>
                                <img v-if="item.customPictureData && item.customPictureData[0]"
                                    :src="item.customPictureData[0].img + '?w=360&h=150'" alt=""
                                    @click="goChartPic(item.customPictureData[0].id)" />
                                <img v-else alt="" />
                                <p v-if="item.customPictureData && item.customPictureData[0] && item.customPictureData[0].title"
                                    @click="goChartPic(item.customPictureData[0].id)">
                                    {{item.customPictureData[0].title}}</p>

                                <h6 v-if="item.customPictureData && item.customPictureData[1]"
                                    @click="goChartPic(item.customPictureData[1].id)">
                                    {{item.customPictureData[1].title}}</h6>
                                <!-- <h6 v-if="item.customPictureData[2]" @click="goChartPic(item.customPictureData[2].id)">
                                    {{item.customPictureData[2].title}}</h6> -->
                            </div>

                        </div>
                        <!-- 专题 -->
                        <div class="special" v-if="activeData['activeIndex'+index] == 4">
                            <div class="top-special">
                                <div class="img-wrap">
                                    <img v-if="item.customSubject[0] && item.customSubject[0].logo1"
                                        :src="item.customSubject[0].logo1" alt=""
                                        @click="goSubject(item.customSubject[0].id)" />
                                </div>
                                <div class="special-content">
                                    <h5 v-if="item.customSubject[0] && item.customSubject[0].name"
                                        @click="goSubject(item.customSubject[0].id)">{{item.customSubject[0].name}}</h5>
                                    <p v-if="item.customSubject[0] && item.customSubject[0].memo1"
                                        v-html="item.customSubject[0].memo1"></p>
                                </div>
                            </div>
                            <div class="bot-special">
                                <p v-for="(s, sind) in item.customSubject" :key="s.id" v-if="sind>0"
                                    @click="goSubject(s.id)">{{s.name}}</p>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <div class="excellent-collection">

                <div class="com-title">
                    <div>
                        <img class="title-img" src="../assets/img/excellent.png" style="height: 26px;" alt="" />
                        <h5>优秀集刊</h5>
                    </div>

                    <!-- <div>
                        <span>更多<img src="../assets/img/more.png" alt="" /></span>
                    </div> -->
                </div>
                <div class="iedolTab">
                    <ul class="fineIedol ">
                        <li v-for="(item, index) in excllentYear" :key="index" @click="changeYear(item)">
                            <p v-show="activeYear!=item">{{item}}</p>
                            <div class="ticon" :class="{'active':activeYear==item}"><i> {{activeYear==item?item:''}}</i>
                            </div>
                        </li>
                    </ul>
                    <router-link :to="{path:'/guide/g', query:{id: activeYear}}" class="lookmore">
                        <!-- target="_blank" -->
                        更多&nbsp;>
                    </router-link>
                </div>
                <div class="swiper-book">
                    <div class="card-carousel-wrapper">
                        <div class="card-carousel--nav__left" @click="moveCarousel(-1)" :disabled="atHeadOfList"></div>
                        <div class="card-carousel">
                            <div class="card-carousel--overflow-container">
                                <div class="card-carousel-cards"
                                    :style="{ transform: 'translateX' + '(' + currentOffset + 'px' + ')'}">
                                    <div class="card-carousel--card" v-for="(item,index) in excllentList" :key="index">
                                        <router-link :to="{path:'/c/c', query:{id:item.id}}" :title="item.name">
                                            <img :src="item.imgPath +'?w=153&h=222'" v-if="item.imgPath" alt="" srcset="">

                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-carousel--nav__right" @click="moveCarousel(1)" :disabled="atEndOfList"></div>
                    </div>
                </div>

            </div>
        </div>
        <div class="right-part">
            <div class="new-resource-wrap  com-right">
                <div class="com-title">
                    <div>
                        <img class="title-img" src="../assets/img/newicon.png" alt="" />
                        <h5>最新资源</h5>
                    </div>

                    <!-- <div>
                        <span>更多<img src="../assets/img/more.png" alt="" /></span>
                    </div> -->
                </div>
                <div class="new-resource">
                    <ul>
                        <li :class="[activeResourceIndex==1 ? 'active-resource' : '']" @click="switchResource(1)">
                            论文
                            <!-- 文章 -->
                        </li>
                        <li :class="[activeResourceIndex==2 ? 'active-resource' : '']" @click="switchResource(2)">集刊
                        </li>
                        <li :class="[activeResourceIndex==3 ? 'active-resource' : '']" @click="switchResource(3)">文集
                        </li>
                    </ul>
                    <div class="new-resource-list" v-if="activeResourceIndex==1">
                        <p class="com-p" v-for="item in resourceData" :key="item.id"
                            @click="goLink(item, item.id, item.title, activeResourceIndex)">
                            <img v-if="item.openAccessStatus == 1" src="../assets/img/lock.png" alt="" />
                            {{item.searchTitle? item.searchTitle : item.title}}
                        </p>
                    </div>

                    <div class="new-resource-list" v-if="activeResourceIndex==2 || activeResourceIndex==3">
                        <div class="new-resource-book">
                            <img v-if="resourceData[0] && resourceData[0].titleImg" :src="resourceData[0].titleImg"
                                @click="goLink(resourceData[0], resourceData[0].id, resourceData[0].title, activeResourceIndex)"
                                alt="" />
                            <img v-else
                                @click="goLink(resourceData[0], resourceData[0].id, resourceData[0].title, activeResourceIndex)"
                                alt="" />
                            <div>
                                <h6
                                    @click="goLink(resourceData[0], resourceData[0].id, resourceData[0].title, activeResourceIndex)">
                                    {{resourceData[0].title}}</h6>
                                <p v-html="resourceData[0].abstractCn"></p>
                            </div>
                        </div>
                        <p class="com-p" v-for="(item, ind) in resourceData" :key="item.id" v-if="ind>0 && ind<3"
                            @click="goLink(item, item.id, item.title, activeResourceIndex)">
                            <img v-if="item.openAccessStatus == 1" src="../assets/img/lock.png" alt="" /> {{item.title}}
                        </p>
                    </div>

                </div>

            </div>
            <div class="new-dynamic-wrap com-right">
                <div class="com-title">
                    <div>
                        <img class="title-img" src="../assets/img/dynamic.png" alt="" />
                        <h5>最新资讯</h5>
                    </div>

                    <div>
                        <span @click="goNewsList">更多<img src="../assets/img/more.png" alt="" /></span>
                    </div>
                </div>
                <div class="new-dynamic">
                    <p class="com-p" v-for="item in dynamicData" :key="item.id" @click="goNews(item)">
                        {{item.title}}</p>
                    <!-- <p class="com-p">第八届人文社会科学集刊年会简报</p>
                    <p class="com-p">2019年第八届人文社会科学集刊年...</p>
                    <p class="com-p">从“一带一路”区域市场开拓看共享...</p>
                    <p class="com-p">免费丨集刊全文数据库及投约稿系统免费...</p>
                    <p class="com-p"><img src="../assets/img/lock.png" alt="" /> 第八届人文社会科学集刊年会精...</p>
                    <p class="com-p"><img src="../assets/img/lock.png" alt="" /> 第八届人文社会科学集刊年会精...</p> -->
                </div>

            </div>
            <div class="open-access-wrap  com-right">
                <div class="com-title">
                    <div>
                        <img class="title-img" src="../assets/img/oa.png" alt="" />
                        <h5>开放获取</h5>
                    </div>

                    <div>
                        <span>
                            <router-link :to="{path: '/oa/openaccess'}">更多<img src="../assets/img/more.png" alt="" />
                            </router-link>
                        </span>
                    </div>
                </div>
                <div class="open-access">
                    <p class="com-p" v-for="(item, index) in openData" :key="index" @click="goOa(item)">
                        <img v-if="item.openAccessStatus == 1" src="../assets/img/lock.png" alt="" /> {{item.searchTitle? item.searchTitle : item.title}}

                    </p>
                    <!-- <p class="com-p"><img src="../assets/img/lock.png" alt="" /> 第八届人文社会科学集刊年会精...</p>
                    <p class="com-p"><img src="../assets/img/lock.png" alt="" /> 第八届人文社会科学集刊年会精...</p>
                    <p class="com-p"><img src="../assets/img/lock.png" alt="" /> 第八届人文社会科学集刊年会精...</p>
                    <p class="com-p"><img src="../assets/img/lock.png" alt="" /> 第八届人文社会科学集刊年会精...</p>
                    <p class="com-p"><img src="../assets/img/lock.png" alt="" /> 第八届人文社会科学集刊年会精...</p>
                    <p class="com-p"><img src="../assets/img/lock.png" alt="" /> 第八届人文社会科学集刊年会精...</p> -->
                </div>
            </div>
            <div class="new-preprint-wrap  com-right">
                <div class="com-title">
                    <div>
                        <img class="title-img" src="../assets/img/preprinticon.png" alt="" />
                        <h5>预印本</h5>
                    </div>

                    <div>
                        <span>
                            <router-link to="/preprint/p">更多<img src="../assets/img/more.png" alt="" /></router-link>
                        </span>
                    </div>
                </div>
                <div class="new-preprint">
                    <p class="com-p" v-for="item in preprintData" :key="item.id" @click="preprintDetail (item)">
                        {{item.title}} </p>
                </div>
            </div>
            <div class="new-special-wrap  com-right">
                <div class="com-title">
                    <div>
                        <img class="title-img" src="../assets/img/specialicon.png" alt="" />
                        <h5>专题</h5>
                    </div>

                    <div>
                        <span @click="goSubjectList">
                            更多<img src="../assets/img/more.png" alt="" />
                        </span>
                    </div>
                </div>
                <div class="new-special ">
                    <ul class="top-new-special">
                        <li>
                            <img v-if="specialData[0]" :src="specialData[0].logo1 + '?w=134&h=87'" alt=""
                                @click="goSubject(specialData[0].id)" />
                            <img v-else src="../assets/img/book.png" alt="" />
                            <p v-if="specialData[0]" @click="goSubject(specialData[0].id)">{{specialData[0].name}}</p>
                        </li>
                        <li>
                            <img v-if="specialData[1]" :src="specialData[1].logo1 + '?w=134&h=87'" alt=""
                                @click="goSubject(specialData[1].id)" />
                            <img v-else src="../assets/img/book.png" alt="" />
                            <p v-if="specialData[1]" @click="goSubject(specialData[1].id)">{{specialData[1].name}}</p>
                        </li>
                    </ul>
                    <p class="com-p" v-for="(item, index) in specialData" v-if="index>1" :key="index"
                        @click="goSubject(item.id)">{{item.name}}</p>
                </div>
            </div>

            <div class="new-media-wrap com-right">
                <div class="com-title">
                    <div>
                        <img class="title-img" src="../assets/img/mediaicon.png" alt="" />
                        <h5>多媒体资源</h5>
                    </div>

                    <div>
                        <router-link to='/chart/c'><span>更多<img src="../assets/img/more.png" alt="" /></span>
                        </router-link>
                    </div>
                </div>
                <div class="new-media">
                    <ul>
                        <li :class="chartPictureInd==1? 'active-new-media' : ''"
                            @click="getChartPictureData('CHART', 1)">图表</li>
                        <li :class="chartPictureInd==2? 'active-new-media' : ''"
                            @click="getChartPictureData('PICTURE', 2)">图片</li>
                    </ul>
                    <img v-if="ChartPictureData && ChartPictureData[0]" @click="chartDetail(ChartPictureData[0].id)"
                        :src="ChartPictureData[0].img + '?w=281&h=175'" alt="" />
                    <img v-else alt="" />
                    <h6 v-if="ChartPictureData && ChartPictureData[0] && ChartPictureData[0].title"
                        @click="chartDetail(ChartPictureData[0].id)" v-html="ChartPictureData[0].title"></h6>

                    <p class="com-p" @click="chartDetail(ChartPictureData[1].id)" v-if="ChartPictureData[1]"
                        v-html="ChartPictureData[1].title">
                    </p>
                    <p class="com-p" @click="chartDetail(ChartPictureData[2].id)" v-if="ChartPictureData[2]"
                        v-html="ChartPictureData[2].title">
                    </p>
                </div>
            </div>

            <div class="new-service-wrap com-right">
                <div class="com-title">
                    <div>
                        <img class="title-img" src="../assets/img/serviceicon.png" alt="" />
                        <h5>服务专区</h5>
                    </div>

                    <!-- <div>
                        <span>更多<img src="../assets/img/more.png" alt="" /></span>
                    </div> -->
                </div>
                <div class="new-service">
                    <p>
                        <router-link to="/iedolNum/i">集刊编号注册</router-link>
                    </p>
                    <p>
                        <router-link :to="{path: '/sD/s', query: {id: 1757279}}">作者服务</router-link>
                    </p>
                    <p><a href="https://www.iedol.cn/#/login" target="_blank">在线投稿</a></p>
                    <p>
                        <router-link :to="{path: '/sD/s', query: {id: 1757278}}">主编服务</router-link>
                    </p>
                    <p><router-link :to="{path: '/sD/s', query: {id: 1103313568}}">资源合作</router-link>
                    <p>
                        <router-link :to="{path: '/sD/s', query: {id: 1103313569}}">定制服务</router-link>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// 关于我们 http://jikan.a.com/admin/api/news/detail?id=1757277
import BannerSwiper from '../components/SwiperBanner'

export default {
    name: '',
    components: { BannerSwiper },
    data() {
        return {
            pageName: '首页',
            curE: [],
            curLibraryData: [],
            activeData: {
                activeIndex0: 0,
                activeIndex1: 0,
                activeIndex2: 0,
                activeIndex3: 0,
                activeIndex4: 0,
                activeIndex5: 0,
                activeIndex6: 0,
                activeIndex7: 0,
                activeIndex8: 0,
                activeIndex9: 0,
                activeIndex10: 0,
                activeIndex11: 0,
                activeIndex12: 0,
                activeIndex13: 0,
                activeIndex14: 0,
                activeIndex15: 0
            },
            activeResourceIndex: 1,
            libraryData: [{}, {}, {}, {}, {}],
            typeList: [],
            curIndex: 1,
            resourceData: [],
            dynamicData: [],
            specialData: [],
            preprintData: [],
            openData: [], //开放获取
            chartPictureInd: 1,
            ChartPictureData: [], //多媒体资源   图片图表
            newsData: [
                {
                  title: '',
                  abstractCn: '',
                  titleImg: ''
                },
                {
                  title: '',
                  abstractCn: '',
                  titleImg: ''
                },
                {
                  title: '',
                  abstractCn: '',
                  titleImg: ''
                },
                {
                  title: '',
                  abstractCn: '',
                  titleImg: ''
                },
                {
                  title: '',
                  abstractCn: '',
                  titleImg: ''
                }
            ],
            swiperList: [],
            excllentYear: [],
            activeYear: 2018,
            excllentList: [],
            currentOffset: 0,
            windowSize: 4,
            paginationFactor: 185
        }
    },
    watch: {
        $route: 'init',
        curE(newValue, oldValue) {
            //排序
            var _this = this
            _this.libraryData = []
            this.curLibraryData.forEach(c => {
                newValue.forEach(n => {
                    if (c.name == n.name) {
                        _this.libraryData.push(n)
                    }
                })
            })
            // console.log('111122223333libraryData', this.libraryData)
            // var x = []
            // newValue.forEach(e => {
            //     x.push(e.sort)
            // })
            // var y = x.sort(function(a, b) {
            //     return a - b
            // })
            // y.forEach(c => {
            //     newValue.forEach(n => {
            //         if (c == n.sort) {
            //             _this.libraryData.push(n)
            //         }
            //     })
            // })
        }
    },
    computed: {
        classOption: function() {
            return {
                step: 0.5,
                limitMoveNum: 4
            }
        },
        atEndOfList() {
            return this.currentOffset <= this.paginationFactor * -1 * (this.excllentList.length - this.windowSize)
        },
        atHeadOfList() {
            return this.currentOffset === 0
        }
    },
    methods: {
        init() {
            this.getDateYear()

            this.getTypeList()
            this.getBannerList()
            this.getLibraryList() //获取子库列表
            this.getResourceArticle() //最新文章
            // this.getResourceCollection()//最新集刊
            this.getDynamic() //最新资讯
            this.getPreprint() //预印本
            this.getSpecial() //专题
            this.getOpenData()
            this.getChartPictureData('CHART', 1)
        },
        getDateYear() {
            var _this = this
            _this.$http.get('/admin/api/cp/excellentYears').then(res => {
                _this.excllentYear = res.data
                _this.activeYear = res.data[0]
                _this.getExcllentJK()
            })
        },
        changeYear(num) {
            this.currentOffset = 0
            this.activeYear = num
            this.getExcllentJK()
            // this.runExcllent();
        },
        getExcllentJK() {
            var _this = this
            _this.$http
                .get('/admin/api/cp/excellent', {
                    params: {
                        year: _this.activeYear
                    }
                })
                .then(res => {
                    var arr = res.data.data
                    _this.excllentList = res.data.data
                })
        },
        moveCarousel(direction) {
            // Find a more elegant way to express the :style. consider using props to make it truly generic
            if (direction === 1 && !this.atEndOfList) {
                this.currentOffset -= this.paginationFactor
            } else if (direction === -1 && !this.atHeadOfList) {
                this.currentOffset += this.paginationFactor
            }
        },
        toJKHome(id, name) {
            this.$router.push({ path: '/c/c', query: { id: id } })
        },
        preprintDetail(item) {
            this.$router.push({ path: '/pD/p', query: { id: item.id } })
        },
        chartDetail(id) {
            this.$router.push({ path: '/chartDetail/c', query: { id: id } })
        },
        goCollected(item) {
            if (item.resourceType == 'COLLECTED_PAPERS') {
                this.$router.push({ path: '/c/c', query: { id: item.id } })
            } else if (item.resourceType == 'WENJI' || item.resourceType == 'BOOK') {
                this.$router.push({ path: '/collectionDetail/c', query: { id: item.id } })
            } else if (item.resourceType == 'ARTICLE') {
                this.$router.push({ path: '/aD/a', query: { id: item.id } })
            }
        },
        goOa(item) {
            if (item.resourceType == 'COLLECTED_PAPERS') {
                this.$router.push({ path: '/c/c', query: { id: item.id } })
            } else if (item.resourceType == 'WENJI' || item.resourceType == 'BOOK') {
                this.$router.push({ path: '/collectionDetail/c', query: { id: item.id } })
            } else if (item.resourceType == 'ARTICLE') {
                this.$router.push({ path: '/aD/a', query: { id: item.id } })
            }
        },
        goLink(item, id, title, num) {
            if (num == 1) {
                //文章
                this.$router.push({ path: '/aD/a', query: { id: id } })
                // , title: title
            } else if (num == 2) {
                //集刊
                if (item.resourceType == 'COLLECTED_PAPERS') {
                    this.$router.push({ path: '/c/c', query: { id: item.id } })
                } else if (item.resourceType == 'WENJI' || item.resourceType == 'BOOK') {
                    this.$router.push({ path: '/collectionDetail/c', query: { id: item.id } })
                } else if (item.resourceType == 'ARTICLE') {
                    this.$router.push({ path: '/aD/a', query: { id: item.id } })
                }
                // , title: title
            } else if (num == 3) {
                //文集
                if (item.resourceType == 'COLLECTED_PAPERS') {
                    this.$router.push({ path: '/c/c', query: { id: item.id } })
                } else if (item.resourceType == 'WENJI' || item.resourceType == 'BOOK') {
                    this.$router.push({ path: '/collectionDetail/c', query: { id: item.id } })
                } else if (item.resourceType == 'ARTICLE') {
                    this.$router.push({ path: '/aD/a', query: { id: item.id } })
                }
            }
        },
        goNewsList() {
            this.$router.push({ path: '/news/n', query: { title: '最新资讯', id: 'sy_zxdt' } })
        },
        goNews(item) {
            if (item.jumpUrl) {
                window.open(item.jumpUrl, '_blank')
            } else if (item.originUrl) {
                window.open(item.originUrl, '_blank')
            } else if (item.mediaType == 'pdf' && item.mediaPath) {
                // pdf
                window.open(item.mediaPath, '_blank')
            } else {
                this.$router.push({ path: '/nD/n', query: { id: item.id } })
            }
        },
        goChartPic(id) {
            this.$router.push({ path: '/chartDetail/c', query: { id: id } })
        },
        goArticle(id, title) {
            this.$router.push({ path: '/aD/a', query: { id: id } })
            // , title: title
        },
        goWenji(item) {
            this.$router.push({ path: '/collectionDetail/c', query: { id: item.id } })
        },
        goExp(id) {
            this.$router.push({ path: '/expert/e', query: { id: id } })
        },
        goIns(id) {
            this.$router.push({ path: '/ins/i', query: { id: id } })
        },
        goSubject(id) {
            this.$router.push({ path: '/subjectDetail/s', query: { id: id } })
        },
        goSubjectList() {
            this.$router.push({ path: '/subject/subject' })
        },

        switchMode(index, num, id, e) {
            this.activeData['activeIndex' + index] = num
            var _this = this

            if (num == 1) {
                //学者机构
                _this.getExpert(e, 10).then(exp => {
                    _this.libraryData[index].customExpertData = exp
                    _this.getIns(e, 10).then(ins => {
                        _this.libraryData[index].customInsData = ins
                        _this.$forceUpdate()
                    })
                })
            } else if (num == 2) {
                //集刊文集
                _this.getChildArticle(e, 'BOOK', 10).then(val1 => {
                    _this.libraryData[index].customBookData = val1.slice(0, 5)
                    _this.getChildArticle(e, 'WENJI', 10).then(val2 => {
                        _this.libraryData[index].customWenjiData = val2
                        _this.$forceUpdate()
                    })
                })
            } else if (num == 3) {
                //图表图片
                _this.getChildArticle(e, 'CHART', 10).then(val3 => {
                    _this.libraryData[index].customChartData = val3
                    _this.getChildArticle(e, 'PICTURE', 10).then(val4 => {
                        _this.libraryData[index].customPictureData = val4
                        _this.$forceUpdate()
                    })
                })
            } else if (num == 4) {
                //专题
                _this.getSubject(e).then(sub => {
                    if (sub.recommendSubjectList) {
                        _this.libraryData[index].customSubject = sub.recommendSubjectList.slice(0, 6)
                    } else {
                        _this.libraryData[index].customSubject = []
                    }
                    _this.$forceUpdate()
                })
            }

            // _this.getExpert(e, 10).then(exp => {
            //         e.customExpertData = exp
            //         _this.getIns(e, 10).then(ins => {
            //             e.customInsData = ins
            //             _this.getSubject(e).then(sub => {
            //                 if (sub.recommendSubjectList) {
            //                     e.customSubject = sub.recommendSubjectList
            //                 } else {
            //                     e.customSubject = []
            //                 }
            //                 _this.getChildArticle(e, 'BOOK', 10).then(val1 => {
            //                     e.customBookData = val1.slice(0, 5)
            //                     _this.getChildArticle(e, 'WENJI', 10).then(val2 => {
            //                         e.customWenjiData = val2
            //                         _this.getChildArticle(e, 'CHART', 10).then(val3 => {
            //                             e.customChartData = val3
            //                             _this.getChildArticle(e, 'PICTURE', 10).then(val4 => {
            //                                 e.customPictureData = val4
            //                                 _this.curE.push(e)
            //                             })
            //                         })
            //                     })
            //                 })
            //             })
            //         })
            //     })
        },
        changeTab(num) {
            // console.log(num)
        },
        switchResource(num) {
            this.activeResourceIndex = num
            if (num == 1) {
                this.getResourceArticle() //最新文章
            } else {
                this.getResourceCollection(num) //最新集刊
            }
        },
        getTypeList() {
            let _this = this
            _this
                .$http({
                    method: 'get',
                    url: '/admin/api/cp/tree',
                    headers: {
                        'Content-Type': 'application/json; charset=UTF-8'
                    }
                })
                .then(res => {
                    _this.typeList = res.data
                })
        },
        getBannerList() {
            var _this = this
            this.$http({
                method: 'get',
                url: '/admin/api/news/list?innerCode=sy_lbt'
            }).then(res => {
                if (res.data.code == 0) {
                    _this.newsData = res.data.data.rows.slice(0, 5)
                }
            })
        },
        getLibraryList() {
            var _this = this
            this.$http({
                method: 'get',
                url: '/admin/api/library/list?parentId=1'
            }).then(res => {
                if (res.data.code == 0) {
                    _this.curLibraryData = res.data.data.rows
                    _this.libraryData = []
                    res.data.data.rows.forEach((e, ind) => {
                        _this.activeData['activeIndex' + ind] = 0
                        _this.getChildList(e)
                    })
                }
            })
        },
        getChildList(e) {
            var _this = this

            var l = ''
            e.customChildLibraryData = []

            e.childLibraryList.forEach((i, ind) => {
                l = l + i.name
                //解决模块名字太长挤不下
                if (l.length > 31) {
                    return
                }
                e.customChildLibraryData.push(i)
            })

            e.customExpertData = []
            e.customInsData = []
            e.customBookData = []
            e.customWenjiData = []
            e.customChartData = []
            e.customPictureData = []
            e.customSubject = []

            _this.getChildArticle(e, 'ARTICLE', 11).then(val => {
                e.customArticleData = val
                _this.curE.push(e)
            })
        },

        getResourceArticle() {
            var _this = this
            this.$http({
                method: 'get',
                url: '/admin/api/article/latest'
            }).then(res => {
                if (res.data.code == 0) {
                    // e.childLibraryData = res.data.data.rows
                    // _this.libraryData.unshift(e)
                    _this.resourceData = []
                    _this.resourceData = res.data.data.slice(0, 6)
                    if (_this.resourceData[0] && _this.resourceData[0].abstractCn) {
                        _this.resourceData[0].abstractCn = _this.resourceData[0].abstractCn.replace(/<(p|div)[^>]*>(<br\/?>|&nbsp;)<\/\1>/gi, '\n').replace(/<br\/?>/gi, '\n').replace(/<[^>/]+>/g, '').replace(/(\n)?<\/([^>]+)>/g, '').replace(/\u00a0/g, ' ').replace(/&nbsp;/g, ' ').replace(/<\/?(img)[^>]*>/gi, '').replace(/&amp;/g,"&").replace(/&lt;/g,"<").replace(/&gt;/g,">").replace(/&#39;/g,"\'").replace(/&quot;/g,"\"").replace(/<\/?.+?>/g,"")
                    }
                }
            })
        },
        getResourceCollection(num) {
            var _this = this
            this.$http({
                method: 'get',
                url: '/admin/api/book/latest',
                params: {
                    resourceType: num == 2 ? 'BOOK' : 'WENJI'
                }
            }).then(res => {
                if (res.data.code == 0) {
                    _this.resourceData = res.data.data
                }
            })
        },
        getDynamic() {
            var _this = this
            this.$http({
                method: 'get',
                url: '/admin/api/news/list?innerCode=sy_zxdt'
            }).then(res => {
                if (res.data.code == 0) {
                    _this.dynamicData = res.data.data.rows.slice(0, 7)
                }
            })
        },
        getPreprint() {
            var _this = this
            this.$http.get('/admin/api/aloneArticle/articleList').then(res => {
                _this.preprintData = res.data.data.rows.slice(0, 7)
            })
        },
        getSpecial() {
            var _this = this
            this.$http({
                method: 'get',
                url: '/admin/api/library/list?parentId=' + 2
            }).then(res => {
                if (res.data.code == 0) {
                    _this.specialData = res.data.data.rows.slice(0, 5)
                }
            })
        },
        getChartPictureData(type, ind) {
            var _this = this
            var e = { id: '' }
            this.chartPictureInd = ind
            this.$http({
                method: 'get',
                url: '/admin/api/photo/latest?resourceType=' + type
            }).then(res => {
                if (res.data.code == 0) {
                    _this.ChartPictureData = res.data.data
                }
            })
        },
        getSubject(e) {
            var _this = this
            return new Promise(function(resolve, reject) {
                _this
                    .$http({
                        method: 'get',
                        url: '/admin/api/library/detail?id=' + e.id
                    })
                    .then(res => {
                        if (res.data.code == 0) {
                            var data = res.data.data
                            resolve(data)
                        }
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        //子库资源信息
        getChildArticle(e, type, size) {
            var _this = this
            return new Promise(function(resolve, reject) {
                _this
                    .$http({
                        method: 'get',
                        url: '/admin/api/library/resource',
                        params: {
                            libraryId: e.id, //  		子库id
                            resourceType: type, // 	资源类型
                            pageSize: size, //		页面列表长度
                            pageNum: 1
                        }
                    })
                    .then(res => {
                        if (res.data.code == 0) {
                            resolve(res.data.data.rows)
                        }
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        getExpert(e, size) {
            var _this = this
            return new Promise(function(resolve, reject) {
                _this
                    .$http({
                        method: 'get',
                        url: '/admin/api/library/scholar',
                        params: {
                            libraryId: e.id //  		子库id
                            // pageSize: size, //		页面列表长度
                            // pageNum: 1
                        }
                    })
                    .then(res => {
                        if (res.data.code == 0) {
                            resolve(res.data.data)
                        }
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        getIns(e, size) {
            var _this = this
            return new Promise(function(resolve, reject) {
                _this
                    .$http({
                        method: 'get',
                        url: '/admin/api/library/archive',
                        params: {
                            libraryId: e.id //  		子库id
                            // pageSize: size, //		页面列表长度
                            // pageNum: 1
                        }
                    })
                    .then(res => {
                        if (res.data.code == 0) {
                            resolve(res.data.data)
                        }
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        getOpenData() {
            var _this = this
            this.$http({
                method: 'post',
                url: '/admin/api/search/find',
                data: {
                    pageSize: 7,
                    pageNo: 1,
                    openAccessStatus: 1,
                    resourceType: 7
                }
            }).then(res => {
                if (res.data.code == 0) {
                    _this.openData = res.data.data.rows
                }
            })
        }
    },
    created() {
        this.init()
    }
}
</script>

<style lang="scss" scoped>
.home-page {
    display: flex;
    justify-content: space-between;

    .com-title {
        height: 45px;
        line-height: 45px;
        border-bottom: 2px solid #dedede;
        display: flex;
        align-items: center;
        justify-content: space-between;
        div {
            display: flex;
            align-items: center;

            img {
                width: 24px;
                height: 24px;
                margin-right: 11px;
                margin-left: 3px;
            }
            h5 {
                height: 45px;
                line-height: 45px;
                font-size: 24px;
                color: #000000;
                border-bottom: 3px solid #bd1a2d;
                a {
                    color: #000000;
                    font-size: 24px;
                    line-height: 51px;
                    display: block;
                }
            }
        }

        ul {
            display: flex;
            li {
                font-size: 18px;
                margin-right: 18px;
                cursor: pointer;
                line-height: 36px;
                color: #333;
            }
        }
        span {
            font-size: 14px;
            color: #404040;
            cursor: pointer;
            a {
                font-size: 14px;
                color: #404040;
            }
            img {
                width: 4px;
                height: 8px;
                margin-left: 10px;
            }
        }
    }
    .com-right {
        padding: 0 20px;
        width: 316px;
        box-sizing: border-box;
        background: #fefefe;
        border: 1px solid #f5f5f5;
        box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
        margin: 24px 0;
    }
    .com-p {
        width: 276px;
        line-height: 17px;
        margin: 0 auto 18px;
        font-size: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-left: 10px;
        display: inline-block;
        position: relative;
        img {
            width: 19px;
            height: 19px;
            margin-right: 4px;

            vertical-align: middle;
        }
    }
    .com-p::before {
        content: '';
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: #ffac13;
        position: absolute;
        left: 0;
        top: 8px;
    }
    .left-part {
        .banner-wrap {
            width: 860px;
            height: 357px;
            box-sizing: border-box;
            // box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
            margin-top: 24px;
        }
        .type-cont-lt .type-iterm {
            position: relative;
            display: inline-block;
            width: 170px;
            height: 357px;
            background: #fff;
            border: 1px solid #e5e5e5;
            box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.1);
            box-sizing: border-box;
            margin-right: 24px;
        }
        .type-cont-lt .type-iterm > li {
            font-size: 18px;
            color: #2e2e2e;

            text-align: center;
            // padding: 0 15px;
            width: 170px;
            box-sizing: border-box;
        }
        .type-cont-lt .type-iterm > li:first-child {
            height: 46px;
            line-height: 46px;
            padding: 0 15px;
            margin-bottom: 6px;
            div {
                height: 46px;

                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 2px solid #ddd;
                img {
                    width: 20px;
                    height: 20px;
                }
                p {
                    font-size: 22px;
                    color: #bd192d;
                    line-height: 46px;
                    border-bottom: 3px solid #bd192d;
                }
                .more {
                    width: 8px;
                    height: 14px;
                }
            }
        }
        .type-cont-lt .type-iterm > li a.type-title {
            display: block;
            width: 140px;
            margin: 0 auto;
            line-height: 50px;
            text-align: center;
            border-bottom: 1px solid #dcdcdc;
            cursor: pointer;
        }
        .type-cont-lt .type-iterm > li:last-child {
            border: none;
            a.type-title {
                border-bottom: none;
            }
        }
        // .type-cont-lt .type-iterm > li > a:before {
        //     content: '';
        //     float: left;
        //     width: 26px;
        //     height: 26px;
        //     margin-left: 26px;
        //     margin-top: 16px;
        //     margin-right: 10px;
        // }
        .type-cont-lt .type-pop {
            position: absolute;
            top: -1px;
            left: 157px;
            z-index: 12;
            width: 692px;
            height: 357px;
            display: none;
        }
        .type-cont-lt .pop-box {
            width: 100%;
            box-sizing: border-box;
            margin-left: 10px;
            border: 1px solid #e5e5e5;
            background: #fff;
            height: 357px;
            overflow-y: auto;
        }
        .type-iterm li:hover .type-pop {
            display: block;
        }
        .type-pop .type-name {
            position: relative;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            padding: 8px 10px 0px 40px;
            border-bottom: 1px solid #f5f5f5;
        }
        .type-pop .type-name a {
            cursor: pointer;
        }
        .type-pop .type-name span {
            position: absolute;
            top: 8px;
            left: 10px;
            z-index: 4;
            font-weight: 600;
        }
        .type-pop .type-name a {
            display: inline-block;
            margin: 0 16px 8px 0;
        }
        // .type-iterm > li:nth-child(1) > a:before {
        //     background: url('../assets/img/icon_nav_zjzx.png') no-repeat;
        //     background-size: 100% 100%;
        // }
        // .type-iterm > li:nth-child(2) a:before {
        //     background: url('../assets/img/icon_nav_jjgl.png') no-repeat;
        //     background-size: 100% 100%;
        // }
        // .type-iterm > li:nth-child(3) > a:before {
        //     background: url('../assets/img/icon_nav_shzf.png') no-repeat;
        //     background-size: 100% 100%;
        // }
        // .type-iterm > li:nth-child(4) > a:before {
        //     background: url('../assets/img/icon_nav_whcm.png') no-repeat;
        //     background-size: 100% 100%;
        // }
        // .type-iterm > li:nth-child(5) > a:before {
        //     background: url('../assets/img/icon_nav_lsdl.png') no-repeat;
        //     background-size: 100% 100%;
        // }
        // .type-iterm > li:nth-child(6) > a:before {
        //     background: url('../assets/img/icon_nav_yywx.png') no-repeat;
        //     background-size: 100% 100%;
        // }

        .library-list {
            width: 860px;
            height: 364px;
            box-sizing: border-box;
            padding: 7px 14px 0;
            background: #fefefe;
            border: 1px solid #f5f5f5;
            box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
            margin: 24px 0;
            .com-title {
                div ul li {
                    // overflow: hidden;
                    // text-overflow: ellipsis;
                    // max-width: 100px;
                    // white-space: nowrap;
                    a {
                        color: #333;
                    }
                }
            }

            .library-content {
                margin-top: 10px;
                .library-title-ul {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 20px;
                    li {
                        width: 158px;
                        height: 36px;
                        line-height: 36px;
                        background: #f0f0f0;
                        color: #616161;
                        text-align: center;
                        font-size: 16px;
                        cursor: pointer;
                    }

                    li:hover {
                        background: #bd1a2d;
                        color: #fff;
                    }
                    .active-library {
                        background: #bd1a2d;
                        color: #fff;
                    }
                    .active {
                        background: #bd1a2d;
                        color: #fff;
                    }
                }
                .content-wrap {
                    height: 220px;
                    background: url(../assets/img/border.png);
                    background-size: 1px 50%;
                    background-repeat: repeat-y;
                    background-position: 50% 0%;

                    ul.academic {
                        width: 100%;

                        li {
                            width: 414px;
                            box-sizing: border-box;
                            line-height: 19px;
                            // padding-bottom: 20px;
                            padding-bottom: 18px;
                            padding-top: 2px;
                            font-size: 16px;

                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            cursor: pointer;
                        }

                        li {
                            padding-left: 10px;
                            position: relative;
                        }
                        li::before {
                            content: '';
                            width: 4px;
                            height: 4px;
                            border-radius: 50%;
                            background: #ffac13;
                            position: absolute;
                            left: 0;
                            top: 8px;
                        }

                        li:nth-child(2n + 2) {
                        }
                        li:nth-child(2n + 1) {
                            padding-left: 31px;
                        }
                        li:nth-child(2n + 1)::before {
                            left: 21px;
                        }
                        li:first-child::before {
                            display: none;
                        }
                        li:first-child {
                            padding-left: 0px;
                            padding-bottom: 1px;
                            padding-left: 0;
                            overflow: auto;
                            white-space: inherit;

                            img {
                                width: 116px;
                                height: 70px;
                                margin-bottom: 3px;
                                cursor: pointer;
                                box-shadow: 0px 2px 6px 0px rgba(15, 11, 5, 0.29);
                                // height: 70px;
                                // margin-bottom: 3px;
                            }
                            div {
                                padding-left: 15px;
                                h5 {
                                    font-size: 16px;
                                    margin-bottom: 11px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                    padding-left: 10px;
                                    position: relative;
                                }
                                h5::before {
                                    content: '';
                                    width: 4px;
                                    height: 4px;
                                    border-radius: 50%;
                                    background: #ffac13;
                                    position: absolute;
                                    left: 0;
                                    top: 8px;
                                }
                                p {
                                    color: #656565;
                                    line-height: 21px;
                                    font-size: 14px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 2; // 控制多行的行数
                                    -webkit-box-orient: vertical;
                                }
                            }
                        }

                        li:nth-child(2) {
                            padding-left: 31px;
                        }
                        li:nth-child(2)::before {
                            left: 21px;
                        }
                    }
                    ul.academic {
                        width: 100%;
                        li:first-child {
                            div {
                                h5 {
                                    margin-bottom: 8px;
                                }
                            }
                        }
                    }
                    .learner-ins {
                        .learner-ins-ul {
                            display: flex;
                            justify-content: space-between;
                            li {
                                width: 414px;
                                display: flex;
                                box-sizing: border-box;
                                padding-right: 22px;
                                img {
                                    width: 116px;
                                    height: 150px;
                                    margin-right: 15px;
                                    cursor: pointer;
                                    box-shadow: 0px 2px 6px 0px rgba(15, 11, 5, 0.29);
                                }
                                div.right-learner {
                                    flex: 1;
                                    h5 {
                                        width: 246px;
                                        font-size: 18px;
                                        margin-bottom: 16px;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                        cursor: pointer;
                                    }
                                    p {
                                        color: #656565;
                                        font-size: 14px;
                                        line-height: 24px;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        display: -webkit-box;
                                        -webkit-line-clamp: 4; // 控制多行的行数
                                        -webkit-box-orient: vertical;
                                    }
                                }
                            }
                            li:nth-child(2) {
                                padding-left: 18px;
                            }
                        }
                        .bot-learner-ins-ul {
                            // margin-top: 14px;
                            display: flex;
                            li {
                                display: flex;
                                flex-wrap: wrap;
                                box-sizing: border-box;
                                padding: 0 20px;
                                justify-content: space-between;
                                p {
                                    line-height: 30px;
                                    font-size: 16px;
                                    box-sizing: border-box;
                                    padding-left: 10px;
                                    position: relative;
                                    cursor: pointer;
                                    width: 125px;
                                    overflow: hidden;
                                    white-space: nowrap;
                                    text-overflow: ellipsis;
                                }
                                p::before {
                                    content: '';
                                    position: absolute;
                                    left: 0;
                                    top: 13px;
                                    width: 4px;
                                    height: 4px;
                                    border-radius: 50%;
                                    background: #ffab13;
                                }
                            }
                            .left-expert {
                                width: 430px;
                                padding-left: 0;

                                p {
                                    min-width: 125px;
                                }
                            }
                            .right-ins {
                                width: 430px;
                                // p:nth-child(2n + 1) {
                                //     width: 245px;
                                // }
                                // p:nth-child(2n + 2) {
                                //     width: 130px;
                                // }
                            }
                            .expert-sty {
                                p {
                                    width: 125px;
                                }
                            }
                            .ins-sty {
                                p {
                                    width: 187px;
                                }
                            }
                            .collected-sty {
                                p {
                                    width: 187px;
                                }
                            }
                            .wenji-sty {
                                p {
                                    width: 187px;
                                }
                            }
                        }
                    }
                    .chart-img {
                        display: flex;
                        div {
                            width: 415px;
                            box-sizing: border-box;
                            position: relative;
                            img {
                                // width: 257px;
                                // // 350px;
                                // height: 150px;
                                // // 204px;
                                width: 360px;
                                height: 170px;
                                display: block;
                                cursor: pointer;
                            }
                            p {
                                position: absolute;
                                left: 0;
                                top: 130px;
                                width: 360px;
                                font-size: 14px;
                                padding-left: 10px;
                                line-height: 40px;
                                color: #fff;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                background: rgba(2, 2, 2, 0.5);
                                cursor: pointer;
                                // background: rgba(238, 234, 239, 0.2);
                            }
                            h6 {
                                font-size: 16px;
                                line-height: 40px;
                                width: 360px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                cursor: pointer;
                                padding-left: 10px;
                                position: relative;
                                margin-top: 5px;
                            }
                            h6::before {
                                position: absolute;
                                left: 0;
                                top: 19px;
                                content: '';
                                width: 4px;
                                height: 4px;
                                border-radius: 50%;
                                background: #ffac13;
                            }
                        }
                        div:first-child {
                        }
                        div:last-child {
                            padding-left: 50px;
                            p {
                                left: 50px;
                            }
                        }
                    }
                    // 专题
                    .special {
                        background: #fff;
                        width: 100%;
                        min-height: 211px;
                        .top-special {
                            display: flex;
                            .img-wrap {
                                margin-right: 13px;
                                margin-bottom: 17px;
                                img {
                                    width: 120px;
                                    height: 81px;
                                    cursor: pointer;
                                }
                            }
                            .special-content {
                                h5 {
                                    color: #333;
                                    font-size: 18px;
                                    margin-bottom: 19px;
                                    cursor: pointer;
                                }
                                p {
                                    color: #656565;
                                    font-size: 14px;
                                    text-overflow: -o-ellipsis-lastline;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 2;
                                    line-clamp: 2;
                                    -webkit-box-orient: vertical;
                                }
                            }
                        }
                        .bot-special {
                            display: flex;
                            justify-content: space-between;
                            flex-wrap: wrap;
                            p {
                                width: 414px;
                                padding-left: 10px;
                                font-size: 16px;
                                position: relative;
                                line-height: 30px;
                                cursor: pointer;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }
                            p:nth-child(2n+1) {
                                border-right: 1px solid #ebebeb;
                            }
                            p:nth-child(2n+2) {
                                padding-left: 31px;
                            }
                            p:nth-child(2n+1)::before {
                                 left: 0;
                             }
                            p:nth-child(2n+2)::before {
                                 left: 19px;
                             }
                                
                            p::before {
                                content: '';
                                position: absolute;
                                top: 15px;
                                // left: 0;
                                width: 4px;
                                height: 4px;
                                display: block;
                                border-radius: 50%;
                                background: #ffab13;
                            }
                        }
                    }
                }
            }
        }
        .excellent-collection {
            width: 860px;
            // height: 364px;
            box-sizing: border-box;
            padding: 7px 14px 0;
            background: #fefefe;
            border: 1px solid #f5f5f5;
            box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
            margin: 24px 0;
            .iedolTab {
                position: relative;
            }
            .iedolTab .lookmore {
                position: absolute;
                bottom: 6px;
                right: -1px;
                z-index: 1;
                color: #bd1a2d;
            }
            .fineIedol {
                position: relative;
                margin-top: 34px;
                margin-bottom: 50px;
                z-index: 0;
            }
            .fineIedol::before {
                position: absolute;
                bottom: 13px;
                left: 0;
                content: '';
                z-index: -1;
                width: 94.5%;
                height: 1px;
                background: #bd1a2d;
            }
            .fineIedol li:first-child {
                margin-left: 120px;
            }
            .fineIedol li {
                display: inline-block;
                width: 50px;
                text-align: center;
                margin: 0 50px;
            }
            .fineIedol li p {
                padding-bottom: 6px;
                position: relative;
            }

            .fineIedol .ticon {
                position: relative;
                width: 28px;
                height: 28px;
                margin: 0 auto;
                cursor: pointer;
                background: #fff;
                border: 1px solid #bd1a2d;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                border-radius: 50%;
                box-sizing: border-box;
                /*background: url('../assets/img/u662.png') no-repeat;*/
            }
            .fineIedol .ticon i {
                position: absolute;
                top: 50%;
                left: 50%;
                margin-left: -9px;
                margin-top: -9px;
                width: 18px;
                height: 18px;
                cursor: pointer;
                background: #bd1a2d;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                border-radius: 50%;
            }
            .fineIedol .ticon.active {
                // position: absolute;
                // top: -6px;
                // width: 84px;
                // height: 84px;

                position: absolute;
                top: 1px;
                width: 74px;
                height: 74px;
            }
            .fineIedol .ticon.active i {
                // width: 70px;
                // height: 70px;
                // margin-left: -35px;
                // margin-top: -35px;
                // font-style: normal;
                // font-size: 26px;
                // line-height: 70px;
                // text-align: center;
                // color: #fff;

                width: 60px;
                height: 60px;
                margin-left: -30px;
                margin-top: -30px;
                font-style: normal;
                font-size: 24px;
                line-height: 63px;
                text-align: center;
                color: #fff;
            }
            // .swiper-button-prev, .swiper-button-next{
            //     position:absolute;
            //     z-index:999;
            //     top:50%;
            //     left:-10px;
            //     margin-top:-30px;
            //     width: 60px;
            //     height: 60px;
            //     background:url(../assets/img/icon_torr_left.png);
            //     background-size: 100% 100%;
            //     /*background: #ff4652;*/
            //     cursor:pointer;
            //     /*display:none;*/
            // }
            // .swiper-button-next{
            //     background:url(../assets/img/icon_torr_right.png);
            //     background-size: 100% 100%;
            //     /*background-position:0 -60px;*/
            //     left:auto;
            //     right:-10px;
            // }
            .swiper-book .card-carousel-wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 20px 0 40px;
                color: #666a73;
            }

            .swiper-book .card-carousel {
                display: flex;
                justify-content: center;
                // width: 958px;
                width: 708px;
            }
            .swiper-book .card-carousel--overflow-container {
                overflow: hidden;
            }
            .swiper-book .card-carousel--nav__left,
            .card-carousel--nav__right {
                display: inline-block;
                width: 30px;
                height: 30px;
                padding: 10px;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                border-top: 4px solid #bd1a2d;
                border-right: 4px solid #bd1a2d;
                cursor: pointer;
                margin: 0px 12px;
                transition: transform 150ms linear;
            }
            .swiper-book .card-carousel--nav__left[disabled],
            .card-carousel--nav__right[disabled] {
                opacity: 0.2;
                border-color: black;
            }
            .swiper-book .card-carousel--nav__left {
                transform: rotate(-135deg);
            }
            .swiper-book .card-carousel--nav__left:active {
                transform: rotate(-135deg) scale(0.9);
            }
            .swiper-book .card-carousel--nav__right {
                transform: rotate(45deg);
            }
            .swiper-book .card-carousel--nav__right:active {
                transform: rotate(45deg) scale(0.9);
            }

            .swiper-book .card-carousel-cards {
                display: flex;
                transition: transform 150ms ease-out;
                transform: translatex(0px);
            }
            .swiper-book .card-carousel-cards .card-carousel--card {
                margin: 0 16px;
                cursor: pointer;
                background-color: #fff;
                z-index: 3;
                margin-bottom: 2px;
                width: 153px;
                height: 222px;
            }
            .swiper-book .card-carousel-cards .card-carousel--card:first-child {
                margin-left: 0;
            }
            .swiper-book .card-carousel-cards .card-carousel--card:last-child {
                margin-right: 0;
            }
            .swiper-book .card-carousel-cards .card-carousel--card img {
                vertical-align: bottom;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
                // border-radius: 4px;
                transition: opacity 150ms linear;
                user-select: none;
                // width: 153px;
                // border: 1px  solid rgba(0, 0, 0, 0.1);
                width: 153px;
                height: 222px;
                box-sizing: border-box;
                border: 1px solid rgba(0, 0, 0, 0.1);
                box-shadow: 0px 0px 6px 1px rgba(15, 11, 5, 0.2);
            }
            .swiper-book .card-carousel-cards .card-carousel--card img:hover {
                opacity: 0.5;
            }
            .swiper-book .card-carousel-cards .card-carousel--card--footer {
                border-top: 0;
                padding: 7px 15px;
            }
            .swiper-book .card-carousel-cards .card-carousel--card--footer p {
                padding: 3px 0;
                margin: 0;
                margin-bottom: 2px;
                font-size: 19px;
                font-weight: 500;
                color: #2c3e50;
                user-select: none;
            }
            .swiper-book .card-carousel-cards .card-carousel--card--footer p:nth-of-type(2) {
                font-size: 12px;
                font-weight: 300;
                padding: 6px;
                background: rgba(40, 44, 53, 0.06);
                display: inline-block;
                position: relative;
                margin-left: 4px;
                color: #666a73;
            }
            .swiper-book .card-carousel-cards .card-carousel--card--footer p:nth-of-type(2):before {
                content: '';
                float: left;
                position: absolute;
                top: 0;
                left: -12px;
                width: 0;
                height: 0;
                border-color: transparent rgba(40, 44, 53, 0.06) transparent transparent;
                border-style: solid;
                border-width: 12px 12px 12px 0;
            }
            .swiper-book .card-carousel-cards .card-carousel--card--footer p:nth-of-type(2):after {
                content: '';
                position: absolute;
                top: 10px;
                left: -1px;
                float: left;
                width: 4px;
                height: 4px;
                border-radius: 2px;
                background: white;
                box-shadow: -0px -0px 0px #004977;
            }
        }
    }
    .right-part {
        .new-resource-wrap {
            // padding: 0 20px;
            // width: 316px;
            height: 357px;
            // box-sizing: border-box;
            // background: #fefefe;
            // border: 1px solid #f5f5f5;
            // box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
            // margin: 24px 0;
            .com-title {
                div {
                    img.title-img {
                        width: 28px;
                        height: 15px;
                    }
                }
            }
            .new-resource {
                ul {
                    display: flex;
                    justify-content: space-between;
                    width: 276px;
                    margin: 14px auto 20px;
                    li {
                        width: 85px;
                        height: 36px;
                        cursor: pointer;
                        font-size: 16px;
                        background: #efefef;
                        text-align: center;
                        line-height: 36px;
                    }
                    // li:hover {
                    //     background: #bd192d;
                    //     color: #fff;
                    // }
                    .active-resource {
                        background: #bd192d;
                        color: #fff;
                    }
                }
                .new-resource-list {
                    p {
                        cursor: pointer;
                    }
                    .new-resource-book {
                        display: flex;
                        justify-content: space-between;
                        img {
                            width: 100px;
                            height: 140px;
                            margin-bottom: 22px;
                            box-shadow: 0px 2px 6px 0px rgba(15, 11, 5, 0.29);
                            cursor: pointer;
                        }
                        div {
                            width: 160px;
                            h6 {
                                width: 160px;
                                font-size: 16px;
                                margin-bottom: 10px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                cursor: pointer;
                            }
                            p {
                                line-height: 22px;
                                font-size: 14px;
                                color: #666;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-line-clamp: 5;
                                -webkit-box-orient: vertical;
                            }
                        }
                    }
                }
            }
        }
        .new-dynamic-wrap {
            // padding: 0 20px;
            // width: 316px;
            height: 365px;
            // box-sizing: border-box;
            // background: #fefefe;
            // border: 1px solid #f5f5f5;
            // box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
            // margin: 24px 0;
            .new-dynamic {
                padding-top: 20px;
                p {
                    cursor: pointer;
                }
            }
        }
        .open-access-wrap {
            // padding: 0 20px;
            // width: 316px;
            height: 365px;
            // box-sizing: border-box;
            // background: #fefefe;
            // border: 1px solid #f5f5f5;
            // box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
            // margin: 24px 0;
            .com-title {
                div {
                    img.title-img {
                        width: 28px;
                        height: 15px;
                    }
                }
            }
            .open-access {
                padding-top: 20px;
                p {
                    cursor: pointer;
                }
            }
        }
        .new-preprint-wrap {
            height: 364px;
            .com-title {
                div {
                    img.title-img {
                        width: 29px;
                        height: 29px;
                    }
                }
            }
            .new-preprint {
                margin-top: 20px;
                p {
                    cursor: pointer;
                }
            }
        }
        .new-special-wrap {
            // padding: 0 20px;
            // width: 316px;
            // box-sizing: border-box;
            // background: #fefefe;
            // border: 1px solid #f5f5f5;
            // box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
            // margin: 24px 0;
            .new-special {
                .top-new-special {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 15px;
                    li {
                        width: 134px;
                        height: 104px;
                        margin-bottom: 15px;
                        cursor: pointer;
                        img {
                            display: block;
                            width: 100%;
                            height: 87px;
                        }
                        p {
                            line-height: 17px;
                            height: 17px;
                            width: 134px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            background: #d1d1d1;
                            font-size: 12px;
                            text-align: center;
                        }
                    }
                }
                .com-p {
                    cursor: pointer;
                }
            }
        }
        .new-media-wrap {
            .new-media {
                margin-top: 20px;
                position: relative;
                ul {
                    display: flex;
                    justify-content: space-between;
                    li {
                        width: 127px;
                        height: 36px;
                        line-height: 36px;
                        text-align: center;
                        background: #efefef;
                        color: #000000;
                        cursor: pointer;
                    }
                    .active-new-media {
                        color: #fff;
                        background: #bd192d;
                    }
                }
                img {
                    width: 281px;
                    height: 175px;
                    margin: 13px auto 16px;
                    cursor: pointer;
                    display: block;
                }
                h6 {
                    position: absolute;
                    left: 0;
                    top: 183px;
                    width: 281px;
                    font-size: 14px;
                    line-height: 40px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    color: #fff;
                    background: rgba(2, 2, 2, 0.5);
                    padding-left: 10px;
                    box-sizing: border-box;
                    cursor: pointer;
                }

                p {
                    cursor: pointer;
                }
            }
        }
        .new-service-wrap {
            .new-service {
                margin-top: 15px;
                display: flex;
                flex-wrap: wrap;
                p {
                    width: 127px;
                    font-size: 16px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    padding-left: 10px;
                    display: inline-block;
                    position: relative;
                    margin-bottom: 15px;
                    a {
                        color: #333;
                        font-size: 16px;
                    }
                }
                p::before {
                    content: '';
                    width: 4px;
                    height: 4px;
                    border-radius: 50%;
                    background: #ffac13;
                    position: absolute;
                    left: 0;
                    top: 8px;
                }
            }
        }
    }
}
</style>
