<template>
    <div class="banner-components">
        <div class="ag7-main">
            <div class="ag7-bannerslide">
                <ul class="slidebox">
                    <li v-for="(item, index) in dataList" :key="index">

                        <a>
                            <img :src="item.titleImg+'?w=666&h=357'" alt="" @click="goNews(item)" style="cursor:pointer;" />
                            <!-- <img v-if="item.titleImg" :src="item.titleImg" alt="" @click="goNews(item)"
                                style="cursor:pointer;" />
                            <img v-else src="../assets/img/test1.jpg" alt="" /> -->
                            <!-- <button  @click="lookMore('2019yqzt_jdxw')">查看更多</button> -->
                        </a>
                    </li>
                </ul>
                <div class="slideinfo">
                    <!-- <div class="slidetitle">
                    <h2></h2>
                </div> -->
                    <div class="slidelist" @click="goDetail($event)">
                        <ul>
                            <li data-h1="Picture information 2" v-for="(item, index) in dataList" :key="index">
                                {{item.title}}</li>
                            <!-- <li data-h1="Picture information 1">
                                动物
                            </li>
                            <li data-h1="Picture information 2">
                                风景
                            </li>
                            <li data-h1="Picture information 3">
                                户外
                            </li>
                            <li data-h1="Picture information 4">
                                汽车
                            </li>
                            <li data-h1="Picture information 2">
                                5风景
                            </li> -->
                        </ul>
                        <span class="mask">{{dataList[0].title}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        dataList: {
            type: Array,
            default: []
        },
        pageName: {
            type: String,
            default: ''
        }
    },
    data() {
        return {}
    },
    watch: {
        dataList: {
            handler(newValue, oldValue) {
                //父组件param对象改变会触发此函数
                // console.log('newValue', newValue)
                this.$nextTick(()=> {
                    this.initData()
                })
                
            }
        }
    },
    mounted() {},
    methods: {
        goDetail (e) {
            var x = e.target.innerHTML.trim()
            // console.log('this.dataList', this.dataList)
            this.dataList.forEach((i)=> {
                if (x == i.title.trim()) {
                    this.goNews(i)
                }
            })
        },
        goNews(item) {
            // if (item.originUrl) {
            //     window.open(item.originUrl, '_blank')
            // } else {
            //     this.$router.push({path: '/nD/n', query: {id: item.id}})
            // }
            if (item.jumpUrl) {
                window.open(item.jumpUrl, '_blank')
            } else if (item.originUrl) {
                window.open(item.originUrl, '_blank')
            } else {
                this.$router.push({ path: '/nD/n', query: { id: item.id } })
            }
        },
        initData() {
            /* 轮播图 */
            var slide = function() {
                var slideWrap = $('.slide'),
                    listButtonBox = $('.buttonlist'),
                    roll = slideWrap.children(),
                    list = roll.children(),
                    len = list.length,
                    move = list.height(),
                    fx = 'easeInSine',
                    curIndex = 0,
                    timer = null,
                    speed = 500,
                    auto = 5000

                var init = function() {
                    list.eq(0)
                        .css('zIndex', 2)
                        .siblings()
                        .hide()
                    var s = ''
                    for (var i = 0; i < len; i++) {
                        if (i === curIndex) {
                            s += '<a  class="active" href="javascript:;"></a>'
                        } else {
                            s += '<a href="javascript:;"></a>'
                        }
                    }
                    listButtonBox.append($(s))
                    listButtonBox.on('mouseenter', 'a', function(e) {
                        var index = $(this).index()
                        skip(curIndex, index)
                    })
                    start()

                    $('.slide')
                        .add(listButtonBox)
                        .hover(
                            function() {
                                clearInterval(timer)
                            },
                            function() {
                                start()
                            }
                        )
                }

                var toNext = function() {
                    var n = curIndex + 1 == len ? 0 : curIndex + 1
                    skip(curIndex, n)
                }

                var skip = function(pr, ne) {
                    if (pr == ne) return
                    // console.log('list', list)
                    list.stop(true, true)
                    list.eq(pr)
                        .css('zIndex', 2)
                        .stop(true, true)
                        .animate(
                            {
                                opacity: 0
                            },
                            speed,
                            fx,
                            function() {
                                $(this).css({ zIndex: 0, display: 'none', opacity: 1 })
                                list.eq(ne).css('zIndex', 2)
                            }
                        )
                    list.eq(ne).css({ display: 'block', zIndex: 1 })
                    // list.eq(ne).css({display : 'block' , zIndex : 1 , opacity : 0}).stop(true).animate({
                    //      opacity : 1
                    // },speed,fx,function(){
                    //      $(this).css({zIndex : 2});
                    // });
                    curIndex = ne
                    listButtonBox
                        .children(true)
                        .eq(ne)
                        .addClass('active')
                        .siblings()
                        .removeClass('active')
                }

                var start = function() {
                    timer = setInterval(function() {
                        toNext()
                    }, auto)
                }

                init()
            }

            function bannerSlide() {
                var box = $('.ag7-bannerslide .slidebox'),
                    buttons = $('.ag7-bannerslide .slidelist'),
                    mask = $('.ag7-bannerslide .mask'),
                    h1 = $('.ag7-bannerslide h2'),
                    h2 = $('.ag7-bannerslide h3'),
                    list = box.children('li'),
                    len = list.length,
                    curIndex = 0,
                    nextIndex = 1,
                    speed = 800,
                    timer = null,
                    delay = null,
                    duration = 5000
                var setTitle = function(index) {
                    var li = buttons.find('li').eq(index)
                    h1.html(li.data('h1'))
                    h2.html(li.data('h2'))
                }

                var tab = function(cur, next) {
                    if (cur === next) {
                        return
                    }
                    // console.log('box', box)
                    // console.log('list', list, next)

                    list.stop(true, true)
                    list.eq(next).css({ zIndex: 1, display: 'block', opacity: 1 })
                    list.eq(curIndex).animate({ opacity: 0 }, function() {
                        $(this).css({ zIndex: 0, display: 'none' })
                        list.eq(next).css('zIndex', 2)
                    })

                    // 小图标遮罩
                    var ccc = buttons
                        .find('li')
                        .eq(next)
                        .text()
                    mask.text(ccc)
                  
                  
                    mask.stop(true, true).animate(
                        {
                            // left : next * (buttons.find('li').eq(next).outerWidth(true) + 6) + 32
                            top: buttons
                                .find('li')
                                .eq(next)
                                .position().top
                            //    +5
                        },
                        300,
                        'easeOutExpo'
                    )
                    setTitle(next)
                    curIndex = next
                }

                var autoPlay = function() {
                    timer = setInterval(function() {
                        var next = curIndex == len - 1 ? 0 : curIndex + 1
                        tab(curIndex, next)
                    }, duration)
                }

                var stop = function() {
                    clearInterval(timer)
                }

                buttons.on('mouseenter', 'li', function() {
                    var next = $(this).index()
                    tab(curIndex, next)
                })

                $('.ag7-bannerslide').hover(
                    function() {
                        stop()
                    },
                    function() {
                        autoPlay()
                    }
                )
                list.eq(0)
                    .css('zIndex', 2)
                    .siblings()
                    .hide()
                setTitle(0)
                autoPlay()
            }
            $(function() {
                bannerSlide()
            })
        }
    }
}
</script>

<style>
.ag7-main {
    /* padding-top: 60px; */
    width: 666px;
    margin: 0 auto;
    /* padding-bottom: 70px; */
    height: 357px;
    box-sizing: border-box;
    float: left;
}

.ag7-main .phone-index-banner {
    display: none;
}

.ag7-bannerslide {
    position: relative;
    margin-bottom: 50px;
}

.ag7-bannerslide .slidebox {
    position: relative;
    /* height: 280px; */
    height: 357px;
}

.ag7-bannerslide .slidebox li {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
}

.ag7-bannerslide a img {
    display: block;
    /* width: 540px;
    height: 280px; */
    width: 666px;
    height: 357px;
}

.ag7-bannerslide .slideinfo {
    width: 100%;
    position: absolute;
    left: 0px;
    bottom: 0;
    z-index: 10;
}

.ag7-bannerslide .slidetitle {
    /* width: 540px; */
    width: 666px;
    color: #fff;
    background: rgba(0, 0, 0, 0.2);
}

.ag7-bannerslide .slidetitle h2 {
    font-size: 18px;
    height: 40px;
    line-height: 40px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 15px;
}

.ag7-bannerslide .slidelist {
    position: absolute;
    bottom: 40px;
    right: 0;
    /* width: 60px; */
    width: 248px;
    height: 260px;
    /* height: 280px; */
    /* height: 357px; */
    color: #fff;
    font-size: 14px;
    /* background-color: #222; */
}

.ag7-bannerslide .slidelist ul {
    position: absolute;
    z-index: 10;
    width: 100%;
    text-align: center;
}

.ag7-bannerslide .slidelist ul li {
    width: 100%;
    height: 51px;
    /* margin: 0px 0 10px; */
    box-sizing: border-box;
    cursor: pointer;
    margin-bottom: 1px;
    background: rgba(24, 14, 13, 0.66);
    padding-left: 27px;
    padding-right: 12px;
    font-size: 16px;
    line-height: 51px;
    color: #fff;
    text-overflow: ellipsis;
    cursor: pointer;
    /* width: 209px; */
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
}

.ag7-bannerslide .slidelist .mask {
    width: 248px;
    height: 51px;
    line-height: 51px;
    padding-left: 27px;
    padding-right: 12px;
    font-size: 16px;
    display: block;
    box-sizing: border-box;
    text-overflow: ellipsis;
    cursor: pointer;
    /* width: 209px; */
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
    z-index: 20;
    color: #fff;
    position: absolute;
    left: 0px;
    top: 0px;
    /* background: red; */
    background: url(../assets/img/ju.png);
    background-size: 100% 100%;
    background-position: 0% 0%;
}
</style>